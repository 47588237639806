<template>
  <div class="min-h-full flex flex-col">
    <div>
      <div class="flex justify-between items-center mb-10">
        <h4 class="text-3xl sm:text-4xl font-bold leading-tight">
          Select Your Booking Option
        </h4>
        <!-- Step counter -->
        <StepsCounter class="ml-8 mb-18" v-if="steps.length" :stepCount="steps.length" :currentStep="currentStep"/>
      </div>
      <div class="mt-6 sm:mt-10 text-gray-500 font-light text-xl">
        What type of Booking do you want?
      </div>
    </div>

    <div class="mt-12">
      <ValidationObserver ref="bookingOptionsForm" v-slot="{ handleSubmit }" tag="div">
        <form method="post" @submit.prevent="handleSubmit(saveOption)">
          <div class="space-y-6">
            <template v-for="(option, index) in showOptionPrices">
              <component
                v-if="option.slug != 'pitch'"
                :is="`${option.slug}-option`"
                :option="option"
                :key="`booking-option-${index}`"
                v-model="selectedOptionId"
                @change="updateSelected"
              />
            </template>
          </div>
          <div class="mt-12 flex justify-center">
            <button
              type="submit"
              class="rounded-full h-16 sm:h-20 bg-indigo-gradiant text-white text-xl font-bold flex justify-center items-center uppercase px-16"
              :class="{'opacity-50': loading || ! selectedBookingOption}"
            >
              <span v-if="loading">
                <loading-icon class="h-5 w-5"/>
              </span>
              <span v-else>Continue</span>
            </button>
          </div>
          <div v-if="currentStep > 1" class="text-center mt-4">
            <button @click.prevent="prevStep" type="button" class="text-sm text-pink-500 hover:underline">Previous step</button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import { chain } from 'lodash'
  import StepsCounter from '@/components/StepsCounter'
  import GuestAppearanceOption from '@/components/Book/show/booking-options/GuestAppearanceOption'
  import SponsorOption from '@/components/Book/show/booking-options/SponsorOption'
  // import MockInterviewOption from '@/components/Book/show/booking-options/MockInterviewOption'
  // import MockInterviewOption from '@/components/details/show/booking-options/MockInterviewOption'

  export default {
    components: {
      StepsCounter,
      GuestAppearanceOption,
      SponsorOption,
      // MockInterviewOption
    },

    data() {
      return {
        loading: false,
        selectedOptionId: null,
        bookingFlowPreferences: {},
      }
    },

    computed: {
      steps() {
        return this.$store.getters['showBooking/enabledSteps']
      },

      currentStep() {
        return this.$store.state.showBooking.currentStep
      },

      questions() {
        return this.$store.state.showBooking.questions
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      show() {
        return this.$store.getters['shows/show']
      },

      guest() {
        return this.$store.getters['showBooking/guest']
      },

      bookingOptions() {
        return this.$store.getters['showBooking/bookingOptions']
      },

      showOptionPrices() {
        return chain(this.bookingOptions)
          .filter(option => option.slug != 'pitch')
          .map(option => {
            return {
              ...option,
              prices: this.show.option_prices.filter(price => price.booking_option_id === option.id)
            }
          })
          .filter(option => option.prices.length)
          .sortBy(option => option.id)
          .value()
      },

      selectedBookingOption() {
        return this.$store.getters['showBooking/selectedBookingOption']
      }
    },

    watch: {
      //
    },

    methods: {
      updateSelected(event) {
        this.selectedOptionId = event.option

        let option = this.showOptionPrices.find(option => option.id == event.option)

        this.$store.commit('showBooking/setSelectedBookingOption', {
          ...option,
          price: event.price,
          amount: event.amount || 1,
        })
      },

      saveOption() {
        this.nextStep()
      },

      determineStepNumber() {
        let step = this.steps.findIndex(step => step.name == 'options')

        if (step === -1) {
          return this.$router.push({ name: this.steps[0].route })
        }

        this.$store.commit('showBooking/setCurrentStep', step + 1)
      },

      nextStep() {
        let nextStepRoute = this.steps[this.currentStep].route

        this.$router.push({ name: nextStepRoute })
      },

      prevStep() {
        let prevStepRoute = this.steps[this.currentStep - 2].route
        this.$store.commit('showBooking/setSelectedBookingOption', null)

        this.$router.push({ name: prevStepRoute })
      },
    },

    mounted() {
      this.determineStepNumber()

      if (! this.guest || ! this.guest.id) {
        this.$router.push({name: this.steps[0].route})
        return
      }

      if (this.selectedBookingOption) {
        this.selectedOptionId = this.selectedBookingOption.id
      }

      this.$store.dispatch('shows/fetchBookingFlowPreferences', this.show.id).then((data) => {
        this.bookingFlowPreferences = data.data.data
      })

      this.$store.commit('guestBooking/setIsPitch', false);
    }
  }
</script>
